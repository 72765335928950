a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: inherit;
}

a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

/* Disables default behavior for cursor in react-pdf-viewer */
.rpv-core__text-layer-text {
  cursor: inherit !important;
}

/* Hides arrows in the number input */
.disable-number-arrows::-webkit-outer-spin-button,
.disable-number-arrows::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* To fix bug with predictions not appearing in LocationAutocompleteInput */
.pac-container {
  z-index: 100000000 !important;
}
